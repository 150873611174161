<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="8">
          <b-button size="sm" variant="danger" class="d-inline-block mr-2 mb-1" @click="openModal('onBlockCalendar')">
            <span class="text-nowrap">{{ msg('blockCalendar') }}</span>
          </b-button>
          <b-button size="sm" variant="primary" class="d-inline-block mr-2 mb-1"
                    @click="openModal('onTreasureContractGenerateMulti')">
            <span class="text-nowrap">{{ msg('Create contract') }}</span>
          </b-button>
          <!--          <b-button size="sm" variant="dark" class="d-inline-block mb-1 mr-2" @click="openModal('onTreasureContractGenerate')">
                      <span class="text-nowrap">{{ msg('Generate treasure rent') }}</span>
                    </b-button>-->
          <b-button size="sm" variant="warning" class="d-inline-block mb-1" @click="()=>showPayments=!showPayments">
          <span class="text-nowrap">
            <i class="fa-solid" :class="{'fa-eye': showPayments, 'fa-eye-low-vision': !showPayments}"></i>
            {{ msg('Payment') }}
          </span>
          </b-button>
        </b-col>
        <b-col cols="4" class="text-right">
          <div class="d-inline-block">
            <b-pagination v-if="pagination != null && pagination.active" :value="pagination.page"
                          :total-rows="pagination.total" :per-page="pagination.amount" first-number last-number
                          @change="onPaginationChange"/>
          </div>
        </b-col>
      </b-row>
      <time-line-calendar ref="timeCalendar" @onPagination="onPaginationUpdate" @onSelect="onSelection"
                          v-bind:show-payments="showPayments"/>
    </b-card>
    <treasure-contract-rent-modals ref="treasure-contract-modals"/>
    <b-modal size="lg" ref="modal-options" centered hide-footer :title="selection.property">
      <b-row v-if="selection">
        <b-col class="text-center"> {{ msg('Selected date range') }}: {{ selection.start| moment('DD.MM.YYYY', true) }}
          -
          {{ selection.end | moment('DD.MM.YYYY', true) }}
        </b-col>
      </b-row>
      <div class="text-center mt-1">
        <b-button size="sm" variant="danger" class="d-inline-block mr-2 mb-1" @click="openModal('onBlockCalendar')">
          <span class="text-nowrap">{{ msg('blockCalendar') }}</span>
        </b-button>
        <b-button size="sm" variant="primary" class="d-inline-block mr-2 mb-1"
                  @click="openModal('onTreasureContractGenerateMulti')">
          <span class="text-nowrap">{{ msg('generatetreasurerentmulti') }}</span>
        </b-button>
        <b-button size="sm" variant="dark" class="d-inline-block mb-1" @click="openModal('onTreasureContractGenerate')">
          <span class="text-nowrap">{{ msg('Generate treasure rent') }}</span>
        </b-button>
        <b-row class="mt-3">
          <b-input-group class="w-100">
            <b-form-input :placeholder="msg('Enter price for given dates')" v-model="selection.price" debounce="500"
                          type="number"/>
            <b-input-group-append>
              <b-button size="sm" variant="success" class="d-inline-block" @click="savePrices">
                <span class="text-nowrap">{{ msg('Save prices') }}</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>


        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TimeLineCalendar from "@/components/widgets/calendar/TimeLineCalendar.vue";
import TreasureContractRentModals from "@/components/modals/treasureContractRentModals.vue";
import {mapActions} from "vuex";

export default {
  name: "TimeLineRentCalendar",
  components: {TreasureContractRentModals, TimeLineCalendar},
  data() {
    return {
      propertyFilterList: [],
      allProperties: [],
      showPayments: false,
      pagination: {page: 1, amount: 100, total: 0, active: false},
      selection: {start: null, end: null, property: null, price: null}
    }
  },
  computed: {
    getSelection() {
      return {...this.selection}
    },
  },
  methods: {
    ...mapActions('realEstate', ['saveStaticPrices']),
    savePrices() {
      this.$refs['modal-options'].hide()
      let data = this.$refs['timeCalendar'].getSelection()
      data.price = this.selection.price
      console.log('savePrices', data)
      let request= {
        realEstateId: data.propertyId,
        start: data.start,
        end: data.end,
        price: data.price
      }
      this.saveStaticPrices(request).then(() => {
        this.$root.$emit('toast', {type: 'success', message: this.msg('Prices saved')})
      }).catch(() => {
        this.$root.$emit('toast', {type: 'error', message: this.msg('Prices not saved')})
      })
    },
    openModal(modalMethod) {
      //console.log('openModal', modalMethod)
      this.$refs['modal-options'].hide()
      let data = this.$refs['timeCalendar'].getSelection()
      this.$refs['treasure-contract-modals'][modalMethod](data.propertyId, data.start, data.end)
    },
    onPaginationUpdate(pagination) {
      this.pagination = pagination
    },
    onPaginationChange(page) {
      this.$refs['timeCalendar'].setResourcesPaginationPage(page)
    },
    onSelection(data) {
      const {start, end, property} = data
      this.selection = {
        start,
        end,
        property: (`${property?.groupId != null ? property?.groupId : ""} ${property?.title}`).trim(),
      };
      this.$refs['modal-options'].show();
      /*  this.propertyFilterList = data.propertyFilterList
        this.allProperties = data.allProperties*/
    }
  }
}
</script>


<style scoped>

</style>
